@import "base",
"subHeader";
/*
image: url(/src/img/index/global_navi_01.png);
repeat: no-repeat;
*/

#subHeader {
    .container {
        h1 {
            
        }
    }
}

#contents {
	#hansel {
		margin-bottom: 23px;
		li {
			@include font_size(12);
			display: inline-block;
			line-height: 23px;
			a {
				color: #084ea3;
				line-height: 23px;
				&:hover {
					text-decoration: none;
				}
			}
			+ li {
				&:before {
					content: url(/src/img/contact/bullet_hansel.png);
					vertical-align: -7px;
					margin-left: 7px;
					margin-right: 7px;
				}
			}
		}
	}
    h2.h2 {
        @include font_size(23);
        color: #ff4c00;
        text-align: center;
        margin-bottom: 15px;
    }
	.information_navi {
		text-align: right;
		margin-bottom: 20px;
		li {
			margin-left: 5px;
			display: inline-block;
		}
	}
    .box_a {
        background: #FFF;
        @include border_radius(4px);
        border: 1px solid #e9e9ea;
        overflow: hidden;
        margin-bottom: 15px;
        h2 {
            @include font_size(17);
            font-weight: bold;
            padding: 24px 14px 17px;
            border-bottom: 1px solid #ededed;
            @include box_shadow(rgba(0, 0, 0, .08) 0px 0px 3px 2px);
            margin-bottom: 10px;
        }
        p {
            margin: 0 20px 20px;
        }
        ul {
            margin: 0 30px 20px;
            padding-left: 30px;
            li {
            	@include font_size(13);
            	margin-bottom: 10px;
            	list-style-type: disc;
            	list-style-position: outside;
				a {
					color: #084ea3;
					line-height: 23px;
					&:hover {
						text-decoration: none;
					}
				}
            }
        }
        table.user_info {
        	width: 920px;
        	margin: 0 auto 20px;
        	border-collapse:collapse;
        	tr {
        		border-top: 1px dotted #ccc;
        		th, td {
        			padding: 10px;
        		}
        		th {
        			text-align: left;
        			@include font_size(14);
        			font-weight: bold;
        			width: 290px;
        			padding-left: 10px;
        		}
        		td {
        			textarea {
        				width: 600px;
        				height: 140px;
        			}
        		}
        	}
        }
    }
	.box_b {
		width: 640px;
		margin: 0 auto 20px;
		background-color: white;
		padding: 20px 30px;
		border: 3px solid #fcedc2;
		@include border_radius(3);
		@include box_shadow(0 1px 4px rgba(0, 0, 0, 0.15), 0 0 3px rgba(225, 216, 193, 0.75));
		table {
			width: 100%;
			th {
				width: 40%;
				@include font_size(14);
				text-align: left;
				font-weight: bold;
				padding: 15px 0;
				line-height: 1.3;
				span {
					@include font_size(13);
				}
				a {
					color: #084ea3;
					line-height: 23px;
					@include font_size(13);
					&:hover {
						text-decoration: none;
					}
				}
			}
			td {
				padding: 15px 0;
			}
		}
	}
    p.agree {
        text-align: center;
        margin-bottom: 30px;
        @include font_size(14);
        input {
            vertical-align: 1px;
        }
    }
	p.login {
		text-align: center;
		input {
			width: 444px;
			height: 69px;
			text-align: left;
			text-indent: -9999em;
			margin-bottom: 15px;
			background: {
				color: transparent;
				image: url(/src/img/login/btn_login.png);
				repeat: no-repeat;
			}
			&:hover {
				background-image: url(/src/img/login/btn_login_on.png);
			}
		}
        #registAccountButton {
            width: 445px;
            height: 70px;
            text-align: left;
            text-indent: -9999em;
            margin-bottom: 15px;
            background: {
                color: transparent;
                image: url(/src/img/regist/btn_regist.png);
                repeat: no-repeat;
            }
            &:hover {
                background-image: url(/src/img/regist/btn_regist_on.png);
            }
        }
        #activateButton {
            width: 445px;
            height: 70px;
            text-align: left;
            text-indent: -9999em;
            margin-bottom: 15px;
            background: {
                color: transparent;
                image: url(/src/img/regist/btn_activate.png);
                repeat: no-repeat;
            }
            &:hover {
                background-image: url(/src/img/regist/btn_activate_on.png);
            }
        }

        input.btn_orange {
            @include font_size(14);
            @include border_radius(4px);
            @include box_shadow(0 2px 3px rgba(0, 0, 0, 0.15));
            text-indent: 0;
            text-align: center;
            width: 350px;
            line-height: 52px;
            font-weight: bold;
            color: #fff;
            background: #f26432;
            text-shadow: 1px 1px 1px #c30;
            margin-bottom: 3px;
            cursor: pointer;
            &:hover {
                background: lighten(#f26432,5%);
            }
        }
	}
    hr.groove {
        border-top: 2px solid #e6dfcd;
        border-bottom: 1px solid #fff;
        margin: 10px 0;
    }
    .sub_utility_navi {
    	text-align: center;
    	margin-bottom: 30px;
    	li {
    		display: inline-block;
    		a {
				color: #333;
				@include font_size(14);
				line-height: 26px;
				font-weight: bold;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
				&:before {
					content: url(/src/img/login/bullet_arrow_r.png);
					margin-right: 5px;
				}
    		}
    		+ li {
    			margin-left: 60px;
    		}
    	}
    }
    .prg_04 {
      text-align: center;
      img {
        vertical-align: top;
        margin-right: 15px;
      }
      margin-bottom: 30px;
    }
    .add_team {
        background: {
            color: transparent;
            image: url(/src/img/team/index/btn_add_team.png);
            repeat: no-repeat;
            position: 0 0;
        }
        width: 444px;
        height: 69px;
        text-align: left;
        text-indent: -9999em;
        text-align: center;
        cursor: pointer;
        &:hover {
            background-image: url(/src/img/team/index/btn_add_team_on.png);
        }
    }
    .btn_show_hide {
        @include font_size(11);
        margin-left: 5px;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #F3F3F3), color-stop(0.00, #fff));
        background: -webkit-linear-gradient(#fff, #F3F3F3);
        background: -moz-linear-gradient(#fff, #F3F3F3);
        background: -o-linear-gradient(#fff, #F3F3F3);
        background: -ms-linear-gradient(#fff, #F3F3F3);
        background: linear-gradient(#fff, #F3F3F3);
        padding: 10px 5px;
        text-decoration: none;
        border: 1px solid #ccc;
        @include border_radius(4px);
        text-align: center;
    }
}
em {
    font-style: normal;
    font-weight: bold;
    color: #ed1c00;
}

#has_address {
    width: 470px;
    overflow: hidden;
    float: left;
    @include borderBox;
    border-right: 1px solid #fff;
    .box_b {
        @include borderBox;
        width: auto;
        margin: 5px 20px 15px;
    }
}
#has_secret {
    width: 470px;
    overflow: hidden;
    float: left;
    @include borderBox;
    border-left: 2px solid #edd2a1;
    .box_b {
        @include borderBox;
        width: auto;
        margin: 5px 20px 15px;
    }
}
